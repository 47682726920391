/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.btn--next[data-v-174fd23f] {
  margin-left: auto;
  background-color: #ebebeb;
  color: black;
  padding: 1rem;
}
.left-section[data-v-174fd23f] {
  padding: 5px 5px;
  background-color: #f2f2f2;
  width: 60%;
}
.recommended-funds-wrapper[data-v-174fd23f] {
  width: 100% !important;
  height: 50rem;
}
.manager-details-heading[data-v-174fd23f] {
  height: 5rem;
  padding: 0 1rem;
  align-items: center;
}
.manager-details-heading h2[data-v-174fd23f] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled[data-v-174fd23f] {
  opacity: 0.2;
}
.card[data-v-174fd23f] {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: inset 0 0 5px silver;
  color: #35495e;
  display: flex;
  font-family: inherit;
  font-size: 12px;
  margin-bottom: 5px;
  padding: 5px 10px;
  white-space: normal;
  white-space: nowrap;
  overflow: auto;
}
.card[data-v-174fd23f]:not(.active) {
  justify-content: space-between;
}
.card__rating[data-v-174fd23f] {
  align-self: flex-end;
  flex-basis: 40%;
  text-align: right;
}
.card.selected[data-v-174fd23f] {
  background: #002f5f;
  color: white;
  flex-direction: column;
}
.card--list[data-v-174fd23f] {
  cursor: pointer;
}
.card--list[data-v-174fd23f]:hover, .card--list[data-v-174fd23f]:focus-visible {
  background-color: #e93f6f;
  color: white;
}
.card .italic[data-v-174fd23f] {
  font-size: smaller;
  font-style: italic;
}
.card .space-between[data-v-174fd23f] {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.text-right[data-v-174fd23f] {
  text-align: right;
}
.content-box[data-v-174fd23f] {
  display: flex;
  flex-direction: row;
}
.search-wrapper[data-v-174fd23f] {
  background: #f2f2f2;
  border-bottom: 1px solid silver;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 5px -5px;
  outline: 10px #f2f2f2 solid;
  padding: 0 0 0.1rem;
}
.search[data-v-174fd23f] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0;
  font-family: inherit;
  font-size: 16px;
  min-height: 40px;
  line-height: 40px;
  border: none;
  border-radius: 5px;
  background: #fff;
  transition: border 0.1s ease;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.filters[data-v-174fd23f] {
  padding-top: 10px;
  display: flex;
  align-items: start;
}
.filters input[type=radio][data-v-174fd23f] {
  display: none;
}
.filters label[data-v-174fd23f] {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.filters input[type=radio]:checked + label[data-v-174fd23f] {
  color: #fff;
  background-color: #e93f6f;
}
.content-box[data-v-174fd23f] {
  border-bottom: 1px solid #bfbfbf;
  border-top: 1px solid #bfbfbf;
}
.details-bottom[data-v-174fd23f] {
  justify-content: center;
  padding: 1rem 0;
}
.details-bottom .discard[data-v-174fd23f] {
  margin-right: 2rem;
}
.details-bottom .discard.btn--default[data-v-174fd23f] {
  background-color: #ebebeb;
  color: black;
}
.fund-details[data-v-174fd23f] {
  margin-bottom: 10px;
  text-align: left;
}
.remove-selection[data-v-174fd23f] {
  background-color: #d2222d;
  color: white;
  padding: 0.5rem;
  margin-left: 0.5rem;
}
.selectors-row[data-v-174fd23f] {
  padding: 5px 5px;
  background: #f2f2f2;
  overflow: auto;
  height: 30rem;
}
.loader-container[data-v-174fd23f] {
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c3c3c3;
  height: 100%;
}
.radio-row[data-v-174fd23f] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
}
.radio-row label[data-v-174fd23f],
.radio-row input[data-v-174fd23f] {
  display: inline-block;
}
.radio-row label[data-v-174fd23f]:hover,
.radio-row input[data-v-174fd23f]:hover {
  cursor: pointer;
}
.radio-row label[data-v-174fd23f] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 2rem;
}
.radio-row input[data-v-174fd23f] {
  width: 2em;
  height: 2rem;
  accent-color: #002f5f;
}
.selected-text[data-v-174fd23f] {
  text-transform: uppercase;
  text-align: right;
}
.selection-wrapper[data-v-174fd23f] {
  padding: 5px 5px;
  background-color: white;
  flex: 1;
}
.selection-association[data-v-174fd23f] {
  display: flex;
  justify-content: space-between;
}
.selected-cards-container[data-v-174fd23f] {
  height: 40rem;
  overflow: auto;
}
.wrap[data-v-174fd23f] {
  white-space: normal;
  /* Allow wrapping */
}