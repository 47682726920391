/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.view-container[data-v-1f01c450] {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 100%;
  background: #f3f3f3;
  position: relative;
}
.add-report[data-v-1f01c450] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 16rem;
  padding: 20px 0.5rem;
  border: solid white 1px;
  background: #e93f6f0a;
  position: relative;
  cursor: pointer;
}
.add-report .add-wrapper[data-v-1f01c450] {
  position: sticky;
  top: 50px;
  bottom: 50px;
}
.add-report .add-wrapper div[data-v-1f01c450] {
  margin: 0 auto;
}
.add-report span[data-v-1f01c450] {
  margin-left: 10px;
  font-size: 13px;
  color: #a1a1a1;
  font-weight: bold;
  text-transform: uppercase;
}
.theme-icon.no-border[data-v-1f01c450] {
  border: none;
}