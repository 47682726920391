/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
table[data-v-72179d16] {
  border-radius: 3px;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}
th[data-v-72179d16] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: left;
}
th[data-v-72179d16],
td[data-v-72179d16] {
  padding: 0.5em 1em;
  border: 1px solid white;
}
tr[data-v-72179d16] {
  border: 1px solid white;
}
tbody td[data-v-72179d16] {
  font-size: 1.2rem;
}
tr[data-v-72179d16]:nth-of-type(even) {
  background: #dadada;
}
.actions[data-v-72179d16] {
  display: flex;
  gap: 1rem;
}
.no-reports-text[data-v-72179d16] {
  text-align: center;
  padding-top: 5rem;
}