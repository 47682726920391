/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.add-column-wrapper[data-v-401b1d6e] {
  padding: 2rem 4rem;
}
label[data-v-401b1d6e] {
  display: block;
}
.manager-date[data-v-401b1d6e],
.manager-mv-cf[data-v-401b1d6e] {
  width: 100%;
  margin-bottom: 1rem;
}
.manager-date select[data-v-401b1d6e],
.manager-mv-cf select[data-v-401b1d6e] {
  width: 100%;
  padding: 0.8rem;
  cursor: pointer;
}
.manager-date select[data-v-401b1d6e]:hover,
.manager-mv-cf select[data-v-401b1d6e]:hover {
  border-color: #409aff;
}
.wrapper[data-v-401b1d6e] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-container[data-v-401b1d6e] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  margin: 20px;
  padding-bottom: 50px;
  font-size: 1.4rem;
}
.section-title[data-v-401b1d6e] {
  margin-bottom: 30px;
  text-decoration: underline;
}
.sub-section-title[data-v-401b1d6e] {
  text-decoration: underline;
  font-size: 1.6rem;
}
.custom-field-set[data-v-401b1d6e] {
  width: 100%;
  margin-top: 50px;
  border-color: #cccccc3d;
}
.label-container[data-v-401b1d6e] {
  display: flex;
  align-items: center;
  width: 95%;
  margin: 5px;
}
.label-text[data-v-401b1d6e] {
  font-weight: bold;
  margin-right: 10px;
  display: inline-block;
  width: 250px;
}
.input-box[data-v-401b1d6e] {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: inherit;
}
.input-box[data-v-401b1d6e]:disabled {
  background-color: #f4f4f4;
  opacity: 1;
}
.currency-input[data-v-401b1d6e] {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #333;
  width: 10%;
  margin-right: 10px;
}
.currency-input[data-v-401b1d6e]:disabled {
  background-color: #f4f4f4;
  opacity: 1;
}
.value-input[data-v-401b1d6e] {
  flex: 1;
}
.add-fund-button[data-v-401b1d6e] {
  margin-top: 10px;
}
.confirmation-section[data-v-401b1d6e] {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #f3f3f3;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -10px 20px -20px;
  width: 100%;
  padding: 5px 0;
}
.confirmation-section .flex[data-v-401b1d6e] {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.confirmation-section .btn[data-v-401b1d6e] {
  margin: 0 50px;
}
.funds-table[data-v-401b1d6e] {
  width: 95%;
  border-collapse: collapse;
  margin-top: 20px;
}
.funds-table th[data-v-401b1d6e],
.funds-table td[data-v-401b1d6e] {
  border: 1px solid #ccc;
  padding: 8px;
}
.funds-table th[data-v-401b1d6e] {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}
.add-new-fund[data-v-401b1d6e] {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}
.add-new-fund span[data-v-401b1d6e] {
  margin-left: 5px;
}
.reasoning-textarea[data-v-401b1d6e] {
  width: 100%;
  padding: 5px;
  background-color: white;
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #000000;
  font-family: inherit;
}
.reasoning-textarea[data-v-401b1d6e]:disabled {
  background-color: #f3f3f3;
}
.fund-selector[data-v-401b1d6e] {
  z-index: 1;
}
.custom-date-picker[data-v-401b1d6e] {
  width: 100%;
  background-color: white;
  flex: 1;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #000000;
  font-family: inherit;
  z-index: 0;
}
.radio-buttons[data-v-401b1d6e] {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.radio-buttons input[type=radio][data-v-401b1d6e] {
  display: none;
}
.radio-buttons label[data-v-401b1d6e] {
  display: inline-block;
  width: 90px;
  padding: 10px;
  text-align: center;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
}
.radio-buttons input[type=radio]:checked + label[data-v-401b1d6e] {
  color: #fff;
  background-color: #e93f6f;
}
.radio-buttons input[type=radio]:disabled + label[data-v-401b1d6e] {
  opacity: 0.6;
  cursor: default;
}
.invalid-input[data-v-401b1d6e] {
  border: 1px solid #f44336;
}
.warning-message[data-v-401b1d6e] {
  color: #f44336;
  font-weight: bold;
  display: inline-block;
}